// serviceNames.ts


// List that stores the names of the services that are going to be displayed in the Home page (its have 250 services)

const serviceNames = [
    "network.service",
    "mysql.service",
    "snap.service",
    "ssh.service",
    "nginx.service",
    "docker.service",
    "systemd-logind.service",
    "cron.service",
    "dbus.service",
    "rsyslog.service",
    "systemd-journald.service",
    "systemd-udevd.service",
    "systemd-resolved.service",
    "systemd-networkd.service",
    "systemd-timesyncd.service",
    "systemd-hostnamed.service",
    "systemd-localed.service",
    "systemd-machined.service",
    "systemd-journal-flush.service",
    "systemd-journal-catalog-update.service",
    "systemd-journal-catalog-update.timer",
    "systemd-tmpfiles-setup.service",
    "systemd-tmpfiles-setup-dev.service",
    "systemd-remount-fs.service",
    "systemd-update-utmp.service",
    "systemd-update-utmp-runlevel.service",
    "systemd-random-seed.service",
    "systemd-sysusers.service",
    "systemd-sysctl.service",
    "systemd-modules-load.service",
    "systemd-backlight@backlight:acpi_video0.service",
    "systemd-backlight@backlight:acpi_video1.service",
    "systemd-backlight@backlight:amdgpu_bl0.service",
    "systemd-backlight@backlight:amdgpu_bl1.service",
    "systemd-backlight@backlight:amdgpu_bl2.service",
    "systemd-backlight@backlight:amdgpu_bl3.service",
    "systemd-backlight@backlight:amdgpu_bl4.service",
    "systemd-backlight@backlight:amdgpu_bl5.service",
    "systemd-backlight@backlight:amdgpu_bl6.service",
    "systemd-backlight@backlight:amdgpu_bl7.service",
    "systemd-backlight@backlight:amdgpu_bl8.service",
    "systemd-backlight@backlight:amdgpu_bl9.service",
    "systemd-backlight@backlight:amdgpu_bl10.service",
    "systemd-backlight@backlight:amdgpu_bl11.service",
    "systemd-backlight@backlight:amdgpu_bl12.service",
    "systemd-backlight@backlight:amdgpu_bl13.service",
    "systemd-backlight@backlight:amdgpu_bl14.service",
    "systemd-backlight@backlight:amdgpu_bl15.service",
    "systemd-backlight@backlight:amdgpu_bl16.service",
    "systemd-backlight@backlight:amdgpu_bl17.service",
    "systemd-backlight@backlight:amdgpu_bl18.service",
    "systemd-backlight@backlight:amdgpu_bl19.service",
    "systemd-backlight@backlight:amdgpu_bl20.service",
    "snapd-network.service",
    "snapd.seeded.service",
    "snapd.autoimport.service",
    "snapd.apparmor.service",
    "snapd.refresh.service",
    "snapd.system-shutdown.service",
    "snapd.socket",
    "snapd.service",
    "snapd.error.service",
    "snapd.snap-repair.service",
    "linux-modules-cleanup.service",
    "linux-modules-cleanup.timer",
    "systemd-update-utmp-last.service",
    "systemd-update-utmp-last.timer",
    "systemd-user-sessions.service",
    "systemd-user-sessions.timer",
    "systemd-tmpfiles-clean.service",
    "systemd-tmpfiles-clean.timer",
    "systemd-tmpfiles-clean-up.service",
    "systemd-tmpfiles-clean-up.timer",
    "systemd-tmpfiles-clean-shutdown.service",
    "systemd-tmpfiles-clean-shutdown.timer",
    "systemd-suspend.service",
    "systemd-hibernate.service",
    "systemd-hybrid-sleep.service",
    "systemd-sleep.service",
    "systemd-halt.service",
    "systemd-poweroff.service",
    "systemd-reboot.service",
    "systemd-kexec.service",
    "systemd-sleep.conf",
    "systemd-sleep.service",
    "systemd-sleep.conf.d",
    "systemd-sleep.conf.d/50-override.conf",
    "systemd-sleep.conf.d/50-override.conf.disabled",
    "systemd-sleep.conf.d/50-override.conf.rpmnew",
    "systemd-sleep.conf.d/50-override.conf.rpmsave",
    "systemd-sleep.conf.d/50-override.conf.rpmsave.1",
    "systemd-sleep.conf.d/50-override.conf.rpmsave.2",
    "systemd-sleep.conf.d/50-override.conf.rpmsave.3",
    "systemd-sleep.conf.d/50-override.conf.rpmsave.4",
    "systemd-sleep.conf.d/50-override.conf.rpmsave.5",
    "systemd-sleep.conf.d/50-override.conf.rpmsave.6",
    "systemd-sleep.conf.d/50-override.conf.rpmsave.7",
    "systemd-sleep.conf.d/50-override.conf.rpmsave.8",
    "systemd-sleep.conf.d/50-override.conf.rpmsave.9",
    "systemd-sleep.conf.d/50-override.conf.rpmsave.10",
    "systemd-sleep.conf.d/50-override.conf.rpmsave.11",
    "systemd-sleep.conf.d/50-override.conf.rpmsave.12",
    "systemd-sleep.conf.d/50-override.conf.rpmsave.13",
    "systemd-sleep.conf.d/50-override.conf.rpmsave.14",
    "systemd-sleep.conf.d/50-override.conf.rpmsave.15",
    "systemd-sleep.conf.d/50-override.conf.rpmsave.16",
    "systemd-sleep.conf.d/50-override.conf.rpmsave.17",
    "systemd-sleep.conf.d/50-override.conf.rpmsave.18",
    "systemd-sleep.conf.d/50-override.conf.rpmsave.19",
    "systemd-sleep.conf.d/50-override.conf.rpmsave.20",
    "systemd-sleep.conf.d/50-override.conf.rpmsave.21",
    "systemd-sleep.conf.d/50-override.conf.rpmsave.22",
    "systemd-sleep.conf.d/50-override.conf.rpmsave.23",
    "systemd-sleep.conf.d/50-override.conf.rpmsave.24",
    "systemd-sleep.conf.d/50-override.conf.rpmsave.25",
    "systemd-sleep.conf.d/50-override.conf.rpmsave.26",
    "systemd-sleep.conf.d/50-override.conf.rpmsave.27",
    "systemd-sleep.conf.d/50-override.conf.rpmsave.28",
    "systemd-sleep.conf.d/50-override.conf.rpmsave.29",
    "systemd-sleep.conf.d/50-override.conf.rpmsave.30",
    "systemd-sleep.conf.d/50-override.conf.rpmsave.31",
    "systemd-openvpn.service",
    "systemd-openvpn@.service",
    "linux-header-modules-cleanup.service",
    "linux-header-modules-cleanup.timer",
    "systemd-update-utmp-runlevel.service",
    "systemd-update-utmp-runlevel.timer",
    "systemd-update-utmp.service",
    "systemd-update-utmp.timer",
    "systemd-update-utmp-last.service",
    "systemd-update-utmp-last.timer",
    "systemd-update-utmp-runlevel.service",
    "systemd-update-utmp-runlevel.timer",
    "systemd-update-utmp.service",
    "systemd-update-utmp.timer",
    "systemd-update-utmp-last.service",
    //mysql services
    "mysql.service",
    "mysql@.service",
    //nginx services
    "nginx.service",
    "nginx@.service",
    "nginx-debug.service",
    "nginx-debug@.service",
    "nginx-debug.socket",
    //docker services
    "docker.service",
    //ssh services
    "ssh.service",
    "ssh@.service",
    "ssh.socket",
    "ssh@.socket",
    //network services
    "network.service",
    "network@.service",
    "network.target",
    "network-online.target",
    "network-pre.target",
    //cron services
    "cron.service",
    "cron.target",
    //dbus services
    "dbus.service",
    "dbus.socket",
    //rsyslog services
    "rsyslog.service",
    "rsyslog.socket",
    //systemd services
    "systemd-logind.service",
    "systemd-logind.socket",
    "systemd-journald.service",
    "systemd-journald.socket",
    "systemd-udevd.service",
    "systemd-udevd.socket",
    "systemd-resolved.service",
    "systemd-resolved.socket",
    "systemd-networkd.service",
    "systemd-networkd.socket",
    "systemd-timesyncd.service",
    "systemd-timesyncd.socket",
    "systemd-hostnamed.service",
    "systemd-hostnamed.socket",
    "systemd-localed.service",
    "systemd-localed.socket",
    "systemd-machined.service",
    "systemd-machined.socket",
    "systemd-journal-flush.service",
    "systemd-journal-flush.socket",
    "systemd-journal-catalog-update.service",
    "systemd-journal-catalog-update.socket",
    "systemd-tmpfiles-setup.service",
    "systemd-tmpfiles-setup-dev.service",
    "systemd-remount-fs.service",
    "systemd-update-utmp.service",
    "systemd-update-utmp-runlevel.service",
    "systemd-random-seed.service",
    //ModemManager services
    "ModemManager.service",
    //avahi services
    "avahi-daemon.service",
    "avahi-daemon.socket",
    //systemd services
    "systemd-modules-load.service",
    "systemd-backlight@.service",
    "systemd-backlight@backlight:acpi_video0.service",
    "systemd-backlight@backlight:acpi_video1.service",
    "systemd-backlight@backlight:amdgpu_bl0.service",

    //Nvidia GPU drivers services
    "nvidia-persistenced.service",
    //Nvidia services
    "nvidia-fallback.service",
    "nvidia-fallback.target",
    //Intel drivers services
    "intel_backlight.service",
    //Intel services
    "intel_backlight@.service",
    //systemd services
    "systemd-backlight@backlight:amdgpu_bl1.service",
    "systemd-backlight@backlight:amdgpu_bl2.service",
    "systemd-backlight@backlight:amdgpu_bl3.service",
    "systemd-backlight@backlight:amdgpu_bl4.service",
    "systemd-backlight@backlight:amdgpu_bl5.service",
    "systemd-backlight@backlight:amdgpu_bl6.service",
    "systemd-backlight@backlight:amdgpu_bl7.service",
    "systemd-backlight@backlight:amdgpu_bl8.service",
    "systemd-backlight@backlight:amdgpu_bl9.service",
    "systemd-backlight@backlight:amdgpu_bl10.service",
    "systemd-backlight@backlight:amdgpu_bl11.service",
    "systemd-backlight@backlight:amdgpu_bl12.service",
    "systemd-backlight@backlight:amdgpu_bl13.service",
    "systemd-backlight@backlight:amdgpu_bl14.service",
    "systemd-backlight@backlight:amdgpu_bl15.service",
    "systemd-backlight@backlight:amdgpu_bl16.service",
    "systemd-backlight@backlight:amdgpu_bl17.service",
    "systemd-backlight@backlight:amdgpu_bl18.service",
    "systemd-backlight@backlight:amdgpu_bl19.service",
    "systemd-backlight@backlight:amdgpu_bl20.service",
    "systemd-backlight@backlight:amdgpu_bl21.service",
    "systemd-backlight@backlight:amdgpu_bl22.service",
    "systemd-backlight@backlight:amdgpu_bl23.service",
    "systemd-backlight@backlight:amdgpu_bl24.service",
    "systemd-backlight@backlight:amdgpu_bl25.service",
    "systemd-backlight@backlight:amdgpu_bl26.service",
    "systemd-backlight@backlight:amdgpu_bl27.service",
    "systemd-backlight@backlight:amdgpu_bl28.service",
    "systemd-backlight@backlight:amdgpu_bl29.service",
    "systemd-backlight@backlight:amdgpu_bl30.service",
    "systemd-backlight@backlight:amdgpu_bl31.service",
    "systemd-backlight@backlight:amdgpu_bl32.service",
    "systemd-backlight@backlight:amdgpu_bl33.service",

    //All done services
    "All services started"
    //Linux welcome message
    
];
const uniqueServiceNames = Array.from(new Set(serviceNames));
  export default uniqueServiceNames;
  